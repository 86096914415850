<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-card-title class="bg-clr">
        <v-btn fab flat @click.native="goback">
          <v-icon class="pointer" v-ripple>arrow_back</v-icon>
        </v-btn>
        <v-layout>
          <v-flex xs3>
            <div class="headline">Driver Details</div>
            <div class="heading muted">{{ driverName }}</div>
          </v-flex>
          <v-flex xs3>
            <v-avatar tile color="#ffa500" class="total avatar">
              <span class="white--text breakword">
                Status
                <span v-if="driverStatus" class="count">Free</span>
                <span v-else class="count">Assignment-{{ assignId }}</span>
              </span>
            </v-avatar>
          </v-flex>
          <v-flex xs3 v-if="!driverStatus">
            <v-avatar tile color="#ffa500" class="total avatar">
              <span class="white--text breakword">
                Driver ID
                <span id="pwd_spn" class="count">{{ driverId }}</span>
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator">
                    <v-icon @click="copy" size="20" color="orange"
                      >content_copy</v-icon
                    ></template
                  >
                  <span class="tooltip">copy to clipboard</span>
                </v-tooltip>
              </span>
            </v-avatar>
          </v-flex>
          <v-flex xs3 v-if="driverStatus">
            <v-avatar tile color="#ffa500" class="total avatar">
              <span class="white--text breakword">
                Driver ID
                <span id="pwd_spn" class="count">{{ driverId }}</span>
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator">
                    <v-icon @click="copy" size="20" color="orange"
                      >content_copy</v-icon
                    >
                  </template>
                  <span class="tooltip">copy to clipboard</span>
                </v-tooltip>
              </span>
            </v-avatar>
          </v-flex>

          <v-flex xs4>
            <v-avatar tile color="#ffa500" class="total avatar">
              <v-btn
                class="messagebtn"
                style="background: orange !important"
                flat
                target="_blank"
                @click="driverProfilePdfDownload()"
                prepend-icon
                color="white darken-1"
              >
                <v-icon size="20" color="white">print</v-icon></v-btn
              >
              <v-btn
                v-if="jobType == 1"
                class="messagebtn"
                style="background: orange !important; padding: 5px"
                flat
                target="_blank"
                @click="driverpdfDownload()"
                prepend-icon
                color="white darken-1"
              >
                <v-icon size="20" color="white">save_alt</v-icon>Download
                Docs</v-btn
              >
            </v-avatar>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="nospace content-border m-t-10">
        <v-tabs
          color="grey lighten-3"
          light
          slot="extension"
          slider-color="orange darken-2"
          v-model="active"
        >
          <v-tab href="#tab-1" @click="goto(1)">Profile</v-tab>
          <v-tab
            v-permissions="'assignment-history-driver'"
            href="#tab-2"
            @click="check()"
            >Bookings</v-tab
          >
        </v-tabs>
        <router-view />
      </v-card-text>
      <v-snackbar
        :timeout="2000"
        top
        style="text-align: center"
        color="orange "
        v-model="textCopy"
        class="white--text"
        v-if="textCopy"
        >{{ textCopy }}</v-snackbar
      >
      <success-dialog
        :content="message"
        :show="success"
        :onclose="closeMessage"
      />
    </v-card>
  </v-container>
</template>

<script>
import DriverDetailProfile from "@/components/Drivers/DriverDetailProfile";
import SuccessDialog from "@/components/Common/SuccessDialog";
import DriverDetailAssignments from "@/components/Drivers/DriverDetailAssignments";
import { StorageKeys, CancellationType } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
//import { TruckTypes } from "../constants";
import { driverProfilePdf, driverDocPdf } from "../../constants/api-urls";
import "vue-loading-overlay/dist/vue-loading.min.css";
export default {
  created() {
    this.$eventBus.$on("accounts", (data) => {
      this.fromAccount = data;
    });
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "driver-detail") {
        this.$router.push(`/drivers`);
      }
    });
    const { id = null } = this.$route.params;
    if (id) {
      this.driverId = id;
    }

    const url = this.$route.path;
    // main url
    if (url.match(/^\/drivers\/[0-9]+$/)) {
      this.active = "tab-1";
      this.$router.push(`/drivers/${id}/profile`);
    } else if (url.match(/^\/drivers\/[0-9]+\/profile$/)) {
      //profile url
      this.active = "tab-1";
    } else {
      this.active = "tab-2"; // assignments url
      this.axios
        .get(`${this.constants.serverUrl}/api/drivers/detail/${this.driverId}`)
        .then(
          (response) => {
            const { driversInfo } = response.data.data;
            this.driverData = driversInfo;
            this.jobType = driversInfo.jobType;
            this.driverStatus = driversInfo.availabilityStatus;

            this.driverName =
              driversInfo.firstName + " " + driversInfo.lastName;
          },
          (error) => {}
        );
    }
    this.$eventBus.$on("driver-Detail", ({ driversInfo }) => {
      this.driverStatus = driversInfo.availabilityStatus;
      this.driverData = driversInfo;
      this.jobType = driversInfo.jobType;
      this.assignId = driversInfo.assignId;
    });
    this.$eventBus.$on("driver-Assignment-Detail", (assignId) => {
      this.assignId = assignId;
    });

    this.$eventBus.$on("on-driver-profile-load", ({ name }) => {
      this.driverName = name;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
  },
  components: {
    DriverDetailProfile,
    SuccessDialog,
    DriverDetailAssignments,
    Loading,
  },
  data() {
    return {
      operationName: "",
      message: "Text is copied",
      active: null,
      fromAccount: false,
      success: false,
      driverId: null,
      textCopy: null,
      assignId: null,
      loading: false,
      driverName: null,
      driverStatus: null,
      driverData: null,
      jobType: 1,
    };
  },
  methods: {
    async check() {
      this.operationName = "assignment-history-driver";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.goto(2);
      } else {
        this.active = "tab-1";
        return;
      }
    },
    closeMessage() {
      this.success = false;
      this.componentKey += 1;
    },
    copy() {
      var copyText = document.getElementById("pwd_spn");
      var textArea = document.createElement("textarea");
      textArea.value = copyText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();
      this.textCopy = "Text is copied";
    },
    next(active) {
      this.active = active;
    },
    setDriver(driverName) {
      this.driverName = driverName;
    },
    goback() {
      this.$router.push("/drivers");
    },
    goto(num) {
      if (num === 1) {
        this.$router.push(`/drivers/${this.driverId}/profile`);
      } else {
        this.$router.push(`/drivers/${this.driverId}/assignments`);
      }
    },
    goToDriverDoc() {
      this.$router.push(`/drivers/${this.driverId}/driver-doc`);
    },
    driverProfilePdfDownload() {
      this.$eventBus.$emit("on-load");
      let url = driverProfilePdf;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let jobType = "Land";
      if (this.driverData.jobType == 1) {
        jobType = "Land";
      }
      if (this.driverData.jobType == 2) {
        jobType = "Air";
      }

      if (this.driverData.jobType == 3) {
        jobType = "Sea";
      }

      let body = {
        driverId: this.driverId,
        workingCountry: this.driverData.workingCountry,
        driverType: jobType,
      };
      this.loading = true;
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          if (response.data) {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);

            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = response.data.data;
            a.download = "documents" + this.driverId;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        },
        (error) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          this.textCopy = error.response.data.message;
        }
      );
    },
    driverpdfDownload() {
      this.$eventBus.$emit("on-load");
      let url = driverDocPdf;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { driverId: this.driverId };
      this.loading = true;
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          if (response.data) {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);

            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = response.data.data;
            a.download = "documents" + this.driverId;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        },
        (error) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          this.textCopy = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style>
.avatar-width {
  min-width: 280px !important;
}
.remove-link-style {
  text-decoration-style: none !important;
}
.m-t-10 {
  margin-top: 10px;
}
</style>
